export const themes = {
  light: {
    colors: {
      one: "#f5f5f5",
      two: "#5B99C2",
      three: "#0d7ffc",
      four: "#1F316F",
      five: "#e0e0e0",      // Light grey for dividers
      six: "#0088cc",    // Same as primary for selected or highlighted items
      text: "#5B99C2",
      background: "#f5f5f5",
      headerBackground: "#f5f5f5",
      componentBackground: "#f5f5f5",
      border : "#5B99C2",
      messageIn:"#b7cee9",
      messageOut:"#d7e1ed",
      text1: "#5B99C20",
      text2: "#5B99C2",
      text3: "#f5f5f5",
      hover: "#e0e0e0",
      selected: "#0d7ffc",
      cancel: "#FF0000",     
    },
    padding: {
      small: "8px",
      medium: "16px",
      large: "32px",
    },
    fontSizes: {
      small: "12px",
      medium: "16px",
      large: "24px",
    },
    borderRadius: {
      small: "4px",
      medium: "8px",
      large: "16px",
    },
    fontFamily: "FARegular, sans-serif", // Default font for the light theme
    fontFamilyBold: "FASolid, sans-serif", // Bold font for the light theme
    fontFace: `
      @font-face {
        font-family: 'FARegular';
        src: url('/fonts/fa-regular-400.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: 'FASolid';
        src: url('/fonts/fa-solid-900.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
      }
    `,
  },
  dark: {
    colors: {
      one: "#162c46",
      two: "#9badc6",
      three: "#9badc6",
      four: "#EEEEEE",
      five: "#601a2b",      // Light grey for dividers
      six: "#0088cc",    // Same as primary for selected or highlighted items
      text: "#9badc6",
      background: "#162c46",
      headerBackground: "#162c46",
      componentBackground: "#162c46",
      border : "#9badc6",
      messageIn:"#0d2136",
      messageOut:"#031525",
      text1: "#9badc6",
      text2: "#9badc6",
      text3: "#f5f5f5",
      hover: "#601a2b",
      selected: "#0088cc",
      cancel: "#FF0000", 
    },
    padding: {
      small: "8px",
      medium: "16px",
      large: "32px",
    },
    fontSizes: {
      small: "12px",
      medium: "16px",
      large: "24px",
    },
    borderRadius: {
      small: "4px",
      medium: "8px",
      large: "16px",
    },
    fontFamily: "FARegular, sans-serif", // Default font for the dark theme
    fontFamilyBold: "FASolid, sans-serif", // Bold font for the dark theme
    fontFace: `
      @font-face {
        font-family: 'FARegular';
        src: url('/fonts/fa-regular-400.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: 'FASolid';
        src: url('/fonts/fa-solid-900.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
      }
    `,
  },
  army: {
    colors: {
      one: "#E7F0DC",
      two: "#597445",
      three: "#658147",
      four: "#729762",
      five: "#e0e0e0",      // Light grey for dividers
      six: "#0088cc",    // Same as primary for selected or highlighted items
      text: "#597445",
      background: "#e7f0dc",
      headerBackground: "#E7F0DC",
      componentBackground: "#E7F0DC",
      border : "#597445",
      messageIn:"#8cd252",
      messageOut:"#c4e2a9",
      text1: "#597445",
      text2: "#597445",
      text3: "#f5f5f5",
      hover: "#e0e0e0",
      selected: "#0088cc",
      cancel: "#FF0000", 
    },
    padding: {
      small: "8px",
      medium: "16px",
      large: "32px",
    },
    fontSizes: {
      small: "12px",
      medium: "16px",
      large: "24px",
    },
    borderRadius: {
      small: "4px",
      medium: "8px",
      large: "16px",
    },
    fontFamily: "FARegular, sans-serif", // Default font for the army theme
    fontFamilyBold: "FASolid, sans-serif", // Bold font for the army theme
    fontFace: `
      @font-face {
        font-family: 'FARegular';
        src: url('public/chat-assets/fonts/fa-regular-400.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: 'FASolid';
        src: url('public/chat-assets/fonts/fa-solid-900.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
      }
    `,
  },
  depth: {
    colors: {
      // one: "#ffffff",   // Light grey background
      // two: "#b92ac5",      // Light blue for header and icons
      one: "#c8b4f680",   // Light grey background
      two: "#ffffff",      // Light blue for header and icons
      twopointfive: "#9a71ee", // use this to create contrast try this idea
      three: "#000000",         // Dark grey/black for primary text
      four: "#888888", // Lighter grey for secondary text
      five: "#e0e0e0",      // Light grey for dividers
      six: "#0088cc",    // Same as primary for selected or highlighted items
      background: "#162c46",
      headerBackground: "#0d2136",
      componentBackground: "#0d2136",
      border : "#5B99C2",
      messageIn:"#0d2136",
      messageOut:"#031525",
      text: "#9badc6",
      text1: "#000000",
      text2: "#888888",
      text3: "#f5f5f5",
      hover: "#601a2b",
      selected: "#5B99C2",
      cancel: "#FF0000", 
    },
    padding: {
      small: "8px",
      medium: "16px",
      large: "32px",
    },
    fontSizes: {
      small: "12px",
      medium: "16px",
      large: "24px",
    },
    borderRadius: {
      small: "4px",
      medium: "8px",
      large: "16px",
    },
    fontFamily: "FARegular, sans-serif", 
    fontFamilyBold: "FASolid, sans-serif",
    fontFace: `
      @font-face {
        font-family: 'FARegular';
        src: url('public/chat-assets/fonts/fa-regular-400.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: 'FASolid';
        src: url('public/chat-assets/fonts/fa-solid-900.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
      }
    `,
  },
  depthTwo: {
    colors: {
      one: "#ffffff",   // white background
      two: "#0b6ff4",      // Light blue for header and icons
      three: "#000000",         // Dark grey/black for primary text
      four: "#2e2e2e", // Lighter grey for secondary text
      five: "#4e4e4e",      // Light grey for dividers
      six: "#0088cc",    // Same as primary for selected or highlighted items
      background: "#f1416c",
      headerBackground: "#f5f5f5",
      componentBackground: "#5B99C2",
      border : "#5B99C2",
      messageIn:"#5B99C2",
      messageOut:"#5B99C3",
      text1: "#000000",
      text2: "#888888",
      text3: "#f5f5f5",
      hover: "#f5f5f5",
      selected: "#0088cc",
      cancel: "#FF0000", 
    },
    padding: {
      small: "8px",
      medium: "16px",
      large: "32px",
    },
    fontSizes: {
      small: "12px",
      medium: "16px",
      large: "18px",
    },
    borderRadius: {
      small: "4px",
      medium: "8px",
      large: "16px",
    },
    fontFamily: "FARegular, sans-serif", 
    fontFamilyBold: "FASolid, sans-serif",
    fontFace: `
      @font-face {
        font-family: 'FARegular';
        src: url('public/chat-assets/fonts/fa-regular-400.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: 'FASolid';
        src: url('public/chat-assets/fonts/fa-solid-900.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
      }
    `,
  },
};
